import React, { useState, useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Button } from '@winnin/insights_ui'
import { GET_BRAND_OPTIONS, SET_BRAND_OPTION } from './query'
import { Content, Form, Label, Title } from '../../styled'
import { Alert } from '../../../Alert'
import Select from '../../../Select'
import Input from '../../../Input'
import { useDebounce, useSaveTerritoryToWorkspace } from '../../../../hooks'
import { useToastStore } from '../../../../stores/ToastStore'
import { getClassName } from '../../../../utils/getClassName'

import * as S from './styles'

export default function WorkspaceBrand({ company, onRequestClose }) {
  const [selectedCategory, setSelectedCategory] = useState(() => {
    return company?.category
      ? {
          label: company?.category?.name,
          value: company?.category?.name,
        }
      : null
  })
  const [selectedBrand, setSelectedBrand] = useState(null)
  const [territoryURL, setTerritoryURL] = useState('')

  const { dispatch } = useToastStore()
  const {
    isFetchingTerritory,
    isSavingTerritory,
    isTerritoryValid,
    isTerritoryLinkInvalid,
    isTerritoryVideoCountInsufficient,
    isTerritoryNotBelongingToWorkspace,
    selectedTerritory,
    saveTerritoryToWorkspace,
    checkTerritoryUrlIsValid,
    validateTerritory,
    resetTerritoryStatus,
  } = useSaveTerritoryToWorkspace()

  const { data, loading } = useQuery(GET_BRAND_OPTIONS, {
    onCompleted: (data) => {
      const brandList = Array.from(
        data?.listBrandSOAOnCategoryPaginated?.data || []
      )

      const currentBrand = brandList.find((item) => {
        return item?.brand?.name === company?.brandSOA
      })

      if (currentBrand) {
        setSelectedBrand({
          label: currentBrand?.brand?.name,
          value: currentBrand?.id,
        })
      }
    },
  })
  const [setBrandOption, { loading: isSavingBrand }] = useMutation(
    SET_BRAND_OPTION,
    {
      onError: (error) => {
        console.error('WorkspaceBrand @ set >>>>>', error)
        dispatch({
          type: 'create',
          message: 'Oops! An error occurred. Please, try again.',
        })
      },
    }
  )

  const availableCategories = useMemo(() => {
    const brandsCategories = data?.listBrandSOAOnCategoryPaginated?.data ?? []
    if (!brandsCategories.length) {
      return []
    }
    const dedupedCategories = brandsCategories.reduce((prev, curr) => {
      const category = curr?.category?.name
      if (prev.includes(category)) {
        return prev
      }
      return [...prev, category]
    }, [])
    const formattedOptions = dedupedCategories.map((item) => {
      return {
        label: item,
        value: item,
      }
    })
    return formattedOptions
  }, [data])

  const availableBrands = useMemo(() => {
    if (!selectedCategory) {
      return []
    }

    const brandsData = data?.listBrandSOAOnCategoryPaginated?.data ?? []
    const brandsWithCategory = brandsData.reduce((prev, curr) => {
      const currentCategoryName = curr?.category?.name
      const selectedCategoryName = selectedCategory?.value
      if (currentCategoryName === selectedCategoryName) {
        return [...prev, curr]
      }
      return prev
    }, [])

    const formattedOptions = brandsWithCategory.map((item) => {
      return {
        label: item?.brand?.name,
        value: item?.id,
      }
    })

    return formattedOptions
  }, [selectedCategory, data])

  const handleValidateTerritoryUrl = async (territoryUrl) => {
    const urlIsValid = await checkTerritoryUrlIsValid(territoryUrl)

    if (!urlIsValid) {
      return
    }

    const workspaceId = company?.panels?.[0]?.id
    validateTerritory(territoryUrl, workspaceId)
  }

  const { debouncedAction: debounceHandleValidateTerritoryUrl } = useDebounce(
    handleValidateTerritoryUrl
  )

  const handleTerritoryURLChange = (event) => {
    const url = event.target.value

    setTerritoryURL(url)

    if (!url) {
      resetTerritoryStatus()
      return
    }

    debounceHandleValidateTerritoryUrl(url)
  }

  const hasError =
    (isTerritoryNotBelongingToWorkspace || isTerritoryLinkInvalid) &&
    territoryURL
  const hasWarning = isTerritoryVideoCountInsufficient && territoryURL
  const canSubmitBrand = selectedBrand && selectedCategory
  const canSubmitTerritory = territoryURL && isTerritoryValid
  const canSubmit =
    (canSubmitBrand && !territoryURL) ||
    (canSubmitBrand && canSubmitTerritory) ||
    (canSubmitTerritory && !selectedBrand && !selectedCategory)

  const handleSubmit = async () => {
    const workspaceId = company?.panels?.[0]?.id

    if (!workspaceId || !canSubmit) {
      return
    }

    if (selectedBrand?.value && selectedCategory) {
      await setBrandOption({
        variables: {
          input: {
            brandSOAOnCategoryId: selectedBrand?.value,
            workspaceId,
          },
        },
      })
    }

    if (isTerritoryValid && territoryURL) {
      await saveTerritoryToWorkspace(territoryURL, workspaceId)
    }

    onRequestClose()
    dispatch({ type: 'create', message: 'Brand configurada com sucesso' })
  }

  return (
    <Content>
      <div className="empty-input-slot" />

      <Title>Categoria core e Brand:</Title>
      <Form>
        <div>
          <Label>Category core</Label>
          <Select
            value={selectedCategory}
            placeholder="Select Category"
            options={availableCategories}
            onChange={(option) => {
              setSelectedCategory(option)
            }}
            isDisabled={loading}
          />
        </div>
        <div>
          <Label>Brand</Label>
          <Select
            value={selectedBrand}
            placeholder="Select Brand"
            options={availableBrands}
            onChange={(option) => {
              setSelectedBrand(option)
            }}
            isDisabled={!selectedCategory || loading}
          />
        </div>
        <div className="empty-input-slot" />
        <div className="empty-input-slot" />
      </Form>

      <div>
        <Title>Categoria expandida na home:</Title>

        <S.WorkspaceBrandField>
          <Label>
            Adicione a URL de um tópico, sub-tópico, ou tópico próprio
          </Label>

          <Input
            name="teritory-url"
            value={territoryURL}
            className={getClassName({
              error: hasError,
              warning: hasWarning,
            })}
            onChange={handleTerritoryURLChange}
          />

          {territoryURL && (
            <>
              {isFetchingTerritory && (
                <Alert status="loading" title="Buscando tópico..." />
              )}
              {selectedTerritory && (
                <Alert
                  status="info"
                  title={`Tópico selecionado: ${selectedTerritory?.name}`}
                />
              )}
              {!selectedTerritory && isTerritoryValid && (
                <Alert
                  status="success"
                  title="Este tópico pertence a este workspace e tem 100+ vídeos nos últimos 7 dias"
                />
              )}
              {isTerritoryVideoCountInsufficient && (
                <Alert
                  status="warning"
                  title="Favor selecionar outro: este tópico pertence a este workspace, mas não tem 100+ vídeos nos últimos 7 dias"
                />
              )}
              {isTerritoryNotBelongingToWorkspace && (
                <Alert
                  status="error"
                  title="Favor selecionar outro: este tópico customizado não pertence a este workspace"
                />
              )}
              {isTerritoryLinkInvalid && (
                <Alert
                  status="error"
                  title="Favor selecionar outro: este link é inválido"
                />
              )}
            </>
          )}
        </S.WorkspaceBrandField>
      </div>

      <S.WorkspaceBrandActions>
        <Button appearance="outline" onClick={onRequestClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!canSubmit || isSavingTerritory || isSavingBrand}
        >
          Save
        </Button>
      </S.WorkspaceBrandActions>
    </Content>
  )
}
